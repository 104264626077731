import {inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanMatchFn, Route,
  Router, RouterStateSnapshot, UrlSegment
} from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) { }
  isAllowed(): boolean {
    if (this.authService.authToken) {
      return true;
    }

    this.authService.logout();
    this.router.navigateByUrl('/login').then(r => {
      return false
    });
    return false;
  }
}
export const canMatchUser: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return inject(AuthGuard).isAllowed();
};
export const canActivateChildUser: CanActivateChildFn = (route: ActivatedRouteSnapshot, segments: RouterStateSnapshot) => {
  return inject(AuthGuard).isAllowed();
};
